import { useState } from "react";
import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import Button from "@mui/material/Button";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { UpdateProfile } from "../../components/UpdateProfile";
import { useParams } from "react-router-dom";
import { Avatar } from "@mui/material";
import { backend_url } from "../../components/Constants";

function CheckingHeader(props) {
  const { token, user } = props;
  const [open, setOpen] = useState(
    user && user.is_updated === true ? false : true
  );
  const { id } = useParams();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    user && (
      <div>
        <div
          className="flex flex-col w-full px-24 sm:px-32"
          component={motion.span}
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
          delay={500}
        >
          <div className="flex flex-col md:flex-row flex-auto md:items-center min-w-0 mb-24 mt-48 sm:mt-64 sm:mb-16">
            <UpdateProfile
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
              open={open}
              setOpen={setOpen}
              token={token}
              user={user}
            />

            <div className="flex flex-auto items-center min-w-0">
              <Avatar
                className="flex-0 w-64 h-64"
                alt="user photo"
                src={`${backend_url}${user.profile_photo}`}
              >
                {user.first_name[0]}
              </Avatar>
              <div className="flex flex-col min-w-0 mx-8">
                <Typography
                  variant="p"
                  className="text-2xl font-semibold tracking-tight leading-7 md:leading-snug truncate"
                >
                  {user && `Welcome back, ${user.first_name} ${user.last_name}`}
                </Typography>

                {/* <div className="flex items-center">
                  <NotificationsNoneIcon color="action" />

                  <Typography
                    className="mx-6 leading-6 truncate"
                    color="text.secondary"
                  >
                    You have 2 new forms to confirm
                  </Typography>
                </div> */}
              </div>
            </div>

            {!id && (
              <div className="flex items-center mt-24 sm:mt-0 sm:mx-8 space-x-12">
                <Button
                  className="whitespace-nowrap"
                  variant="contained"
                  color="secondary"
                  startIcon={
                    <AccountCircleIcon size={20}>
                      heroicons-solid:mail
                    </AccountCircleIcon>
                  }
                  onClick={handleClickOpen}
                >
                  Update Profile
                </Button>
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col sm:flex-row space-y-16 sm:space-y-0 flex-1 items-center mt-4 mb-24 px-24">
          {" "}
        </div>
      </div>
    )
  );
}

export default CheckingHeader;
