import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InputAdornment from "@mui/material/InputAdornment";
import axios from "axios";
import * as yup from "yup";
import { lighten } from "@mui/material/styles";
import InfoIcon from "@mui/icons-material/Info";
import FileUploadIcon from "@mui/icons-material/FileUpload";
// import _ from "../../@lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import TravelSnackbar from "../../components/TravelSnackbar";
import Loading from "../../components/Loading";
import kenya_flag from "../../assets/kenya_flag.png";
import { backend_url } from "../../components/Constants";
import NavLinkAdapter from "../../components/NavLinkAdapter";
import DraftWysiwygEditor from "../editor/TextEditor";

export default function Part4(props) {
  const {
    token,
    delegate_data,
    delegate_data_len,
    depature_and_return_data,
    trip_data,
    reviewer,
  } = props;
  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [newdesignation, setNewdesignation] = useState(false);

  const [choices, setChoices] = useState([]);
  // const [dollar_ksh, setDollarKsh] = useState(
  //   trip_data !== undefined && trip_data.sponsor_status === "not_sponsored"
  //     ? 0
  //     : null
  // );
  const [job_group2, setJob_group2] = useState(
    delegate_data !== undefined ? delegate_data.job_group : ""
  );
  // const [job_group, setJob_group] = useState(
  //   delegate_data !== undefined ? delegate_data.job_group : ""
  // );
  const [srcrate, setSrcrate] = useState("");

  const ministries =
    choices &&
    choices.ministry !== undefined &&
    choices.ministry.map((ministries) => ministries[0]);

  const country = trip_data && trip_data.country;

  useEffect(() => {
    fetch(
      `${backend_url}/traveller/gokcost/?` +
        new URLSearchParams({
          country: country,
        }),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setSrcrate(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, [token, country]);

  const days =
    depature_and_return_data &&
    (new Date(depature_and_return_data.date_of_return).getTime() -
      new Date(depature_and_return_data.date_of_dep).getTime()) /
      (1000 * 3600 * 24);
  const days_rounded =
    days && days.toString().charAt(1) === "."
      ? parseInt(days.toString().charAt(0)) + 1
      : days;
  const src_rate =
    srcrate.length !== 0 && srcrate[country][job_group2.toLowerCase()];
  // const per_diem =
  //   dollar_ksh !== null ? src_rate * days_rounded * dollar_ksh : "__";
  const per_diem = src_rate * days_rounded;
  const accomodation_cost =
    trip_data !== undefined ? trip_data.accomo_cost : null;

  const job_group1 = job_group2;

  useEffect(() => {
    fetch(`${backend_url}/traveller/choices/`)
      .then((res) => res.json())
      .then((data) => {
        setChoices(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  const schema = yup.object().shape({
    full_name: yup.string().required("You must enter full name"),
    personal_no: yup.number().required("You must enter personal number"),
    designation: yup.string().required("Please enter the designation"),
    designation_new:
      newdesignation === false
        ? yup.string().notRequired("Please type in the designation")
        : yup.string().required("Please type in the designation"),
    prof_qualification: yup
      .string()
      .required("You must enter professional qualification"),
    prof_qualification_prefix: yup.string().notRequired(),
    ministry: yup
      .string()
      .required()
      .oneOf(
        ministries !== false ? ministries : [],
        "Please select the ministry"
      ),
    job_group: yup
      .string()
      .required()
      .oneOf(
        [
          "F4",
          "U",
          "V",
          "E5",
          "F1",
          "F2",
          "E3",
          "E4",
          "S",
          "T",
          "D5",
          "E1",
          "E2",
          "P",
          "Q",
          "R",
          "D1",
          "D2",
          "D3",
          "K",
          "L",
          "M",
          "N",
          "F",
          "G",
          "H",
          "I",
          "J",
          "A",
          "B",
          "C",
          "D",
          "E",
          "New",
        ],
        "Please select the job group"
      ),
    passport_no: yup
      .string()
      .required("You must enter your passport number")
      .min(4, "Number is too short - should be 4 chars minimum")
      .nullable(true),
    email_address: yup
      .string()
      .email("The email should end with .go.ke")
      .required("You must enter a valid email"),
    id_number: yup
      .string()
      .required("You must enter your ID number")
      .min(4, "Number is too short - should be 4 chars minimum")
      .nullable(true),
    telephone_no: yup
      .string()
      .required("You must enter your telephone number")
      .min(4, "Number is too short - should be 4 chars minimum")
      .nullable(true),
    role: yup.string().required("You must enter role"),
    delegate_justification1:
      reviewer === false
        ? delegate_data_len !== undefined
          ? (
              trip_data.user !== undefined &&
              trip_data.user.designation === "CS"
                ? delegate_data_len.length >= 3
                : trip_data.user.designation === "CEO"
                ? delegate_data_len.length >= 0
                : delegate_data_len.length >= 2
            )
            ? yup.mixed().required("Please justify why you have more delegates")
            : yup.mixed().notRequired()
          : yup.mixed().required("Please reupload the justification letter")
        : yup.mixed().notRequired(),
    // delegate_justification:
    //   delegate_data !== undefined
    //     ? delegate_data.length > 3
    //       ? yup.mixed().required("Please upload the delegation letter")
    //       : yup.string().notRequired()
    //     : yup.string().notRequired(),
    // delegate_justification:
    //   delegate_data !== undefined
    //     ? delegate_data.delegate_justification
    //       ? yup.string().required("This field is required")
    //       : yup.string().notRequired()
    //     : yup.string().notRequired(),
    ticket_cost: yup.number().required(),
    participant_cost: yup.number().required(),
  });

  const defaultValues = {
    full_name: delegate_data !== undefined ? delegate_data.full_name : "",
    personal_no: delegate_data !== undefined ? delegate_data.personal_no : "",
    designation: delegate_data !== undefined ? delegate_data.designation : "",
    designation_new:
      delegate_data !== undefined ? delegate_data.designation_new : "",
    ministry: delegate_data !== undefined ? delegate_data.ministry : "",
    prof_qualification:
      delegate_data !== undefined ? delegate_data.prof_qualification : "",
    prof_qualification_prefix:
      delegate_data !== undefined
        ? delegate_data.prof_qualification.split(" ")[0].slice(0, -1)
        : "",
    delegate_justification1: null,
    // delegate_justification:
    //   delegate_data !== undefined ? delegate_data.delegate_justification : "",
    email_address:
      delegate_data !== undefined ? delegate_data.email_address : "",
    telephone_no: delegate_data !== undefined ? delegate_data.telephone_no : "",
    id_number: delegate_data !== undefined ? delegate_data.id_number : "",
    job_group: delegate_data !== undefined ? delegate_data.job_group : "",
    role: delegate_data !== undefined ? delegate_data.role : "",
    passport_no: delegate_data !== undefined ? delegate_data.passport_no : "",
    // accomo_cost: delegate_data !== undefined ? delegate_data.accomo_cost : "",
    ticket_cost: delegate_data !== undefined ? delegate_data.ticket_cost : "",
    participant_cost:
      delegate_data !== undefined ? delegate_data.participant_cost : "",
  };

  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    // isValid, dirtyFields,
    errors,
  } = formState;

  function submit({
    full_name,
    personal_no,
    designation,
    designation_new,
    ministry,
    email_address,
    telephone_no,
    job_group,
    role,
    id_number,
    prof_qualification,
    prof_qualification_prefix,
    delegate_justification1,
    // delegate_justification,
    passport_no,
    ticket_cost,
    participant_cost,
  }) {
    const delegateInfo = {
      full_name,
      personal_no,
      designation_new,
      designation,
      ministry,
      email_address,
      telephone_no,
      job_group,
      id_number,
      prof_qualification: `${prof_qualification_prefix}: ${prof_qualification}`,
      prof_qualification_prefix,
      delegate_justification:
        delegate_justification1 !== null ? delegate_justification1 : null,
      role,
      passport_no,
      accomo_cost:
        per_diem === ("__" || 0 || null || undefined)
          ? accomodation_cost
          : per_diem,
      ticket_cost,
      participant_cost,
      trip:
        delegate_data !== undefined
          ? delegate_data.trip.trip_id
          : trip_data.trip_id,
      id: delegate_data !== undefined ? delegate_data.delegate_id : "",
      reviewer: reviewer === true ? true : false,
    };

    axios
      .put(
        `${backend_url}/traveller/delegates/`,
        delegateInfo,
        {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        },
        { withCredentials: true }
      )
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Delegate Updated Successfully!");
        setLoading(false);
        delegate_data === undefined && window.location.reload();
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(
          err.code === "ERR_NETWORK" && "Network error, please try again later."
        );
        setMessage(
          err.response.data?.details
            ? err.response.data?.details
            : err.response.data
        );
        setLoading(false);
      });
  }

  return (
    <form
      name="delegateInfo"
      noValidate
      className="flex flex-col justify-center w-full mt-8 "
      onSubmit={
        handleSubmit(submit)
        // handleSubmit(submitTrip);
      }
    >
      {openMessage === true && (
        <TravelSnackbar
          openMessage={openMessage}
          severity={severity}
          message={message}
        />
      )}

      <div>
        <Typography
          component="p"
          variant="p"
          className="pt-16 pb-8 sm:pb-16 text-xs font-semibold"
        >
          (This should include all ministerial/state corporation/agency officers
          attending the same event, meeting, conference, seminar, workshop etc)
        </Typography>

        <div>
          <Typography
            component="p"
            variant="p"
            className="p-6 sm:p-12 text-xs font-semibold"
          >
            Cost of the Trip and Sponsorship for DELEGATION:
          </Typography>
        </div>

        <div className="sm:flex m-4">
          <Controller
            name="full_name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                sx={{ sm: { m: 1 }, m: 1 }}
                label="Delegate (name):"
                id="full_name"
                type="text"
                error={!!errors.full_name}
                helperText={errors?.full_name?.message}
                variant="outlined"
                required
                fullWidth
              />
            )}
          />

          <Controller
            name="personal_no"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                sx={{ sm: { m: 1 }, m: 1 }}
                label="Personal/Employment number"
                id="personal_no"
                type="number"
                error={!!errors.personal_no}
                helperText={errors?.personal_no?.message}
                variant="outlined"
                required
                fullWidth
              />
            )}
          />
        </div>

        <div className="flex w-full">
          <div className="flex w-full">
            <Controller
              name="designation"
              control={control}
              render={({ field }) => (
                <FormControl
                  sx={{ m: 1 }}
                  fullWidth
                  error={!!errors.designation}
                >
                  <InputLabel id="designation1">Designation</InputLabel>
                  <Select
                    labelId="designation1"
                    id="designation"
                    label="Designation"
                    variant="outlined"
                    {...field}
                  >
                    <MenuItem
                      value={"Other"}
                      onClick={() => setNewdesignation(true)}
                    >
                      Other
                    </MenuItem>
                    <MenuItem
                      value={"CHAIRPERSON"}
                      onClick={() => setNewdesignation(false)}
                    >
                      Chairperson
                    </MenuItem>
                    <MenuItem
                      value={"CEO"}
                      onClick={() => setNewdesignation(false)}
                    >
                      CEO
                    </MenuItem>
                    <MenuItem
                      value={"PS"}
                      onClick={() => setNewdesignation(false)}
                    >
                      PS
                    </MenuItem>
                    <MenuItem
                      value={"CS"}
                      onClick={() => setNewdesignation(false)}
                    >
                      CS
                    </MenuItem>
                  </Select>
                  <FormHelperText>
                    {errors?.designation?.message}
                  </FormHelperText>
                </FormControl>
              )}
            />

            <br />

            {newdesignation === true && (
              <Controller
                name="designation_new"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{ m: 1 }}
                    label="Designation Name:"
                    id="designation_new"
                    type="text"
                    error={!!errors.designation_new}
                    helperText={errors?.designation_new?.message}
                    variant="outlined"
                    required
                    fullWidth
                  />
                )}
              />
            )}
          </div>

          <Controller
            name="id_number"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                sx={{ m: 1 }}
                label="ID number"
                type="number"
                error={!!errors.id_number}
                helperText={errors?.id_number?.message}
                variant="outlined"
                required
                fullWidth
              />
            )}
          />
        </div>

        <div className="sm:flex m-4">
          <div className="flex w-128">
            <Controller
              name="prof_qualification_prefix"
              control={control}
              render={({ field }) => (
                <FormControl
                  sx={{ m: 1 }}
                  fullWidth
                  error={!!errors.prof_qualification_prefix}
                >
                  <InputLabel id="prof_qualification_prefix">
                    Professional Qualification
                  </InputLabel>
                  <Select
                    labelId="prof_qualification_prefix"
                    id="prof_qualification_prefix"
                    label="Professional Qualification"
                    variant="outlined"
                    {...field}
                  >
                    <MenuItem value={"Certificate"}>Certificate</MenuItem>
                    <MenuItem value={"Diploma"}>Diploma</MenuItem>
                    <MenuItem value={"Bsc"}>Bsc</MenuItem>
                    <MenuItem value={"Msc"}>Msc</MenuItem>
                    <MenuItem value={"Phd"}>Phd</MenuItem>
                  </Select>
                  <FormHelperText>
                    {errors?.prof_qualification_prefix?.message}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </div>

          <div className="flex w-full">
            <Controller
              name="prof_qualification"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  sx={{ sm: { m: 1 }, m: 1 }}
                  label="Highest Professional Qualification Certification:"
                  id="prof_qualification"
                  type="text"
                  error={!!errors.prof_qualification}
                  helperText={errors?.prof_qualification?.message}
                  variant="outlined"
                  required
                  fullWidth
                />
              )}
            />
          </div>

          <Controller
            name="email_address"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                sx={{ sm: { m: 1 }, m: 1 }}
                label="Email"
                id="email"
                type="email"
                error={!!errors.email_address}
                helperText={errors?.email_address?.message}
                variant="outlined"
                required
                fullWidth
              />
            )}
          />
        </div>

        <div className="sm:flex m-4">
          <Controller
            name="telephone_no"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                sx={{ sm: { m: 1 }, m: 1 }}
                label="Telephone number"
                variant="outlined"
                error={!!errors.telephone_no}
                helperText={
                  <Typography variant="caption" color="error">
                    {" "}
                    {errors?.telephone_no?.message}{" "}
                  </Typography>
                }
                id="telephone_no"
                required
                fullWidth
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box
                        component="span"
                        sx={{ "& > img": { mr: 0, flexShrink: 0 } }}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          src={kenya_flag}
                          alt="kenya flag"
                        />
                        <span className="ml-4 font-medium">+254</span>
                      </Box>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />

          <Controller
            name="passport_no"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                sx={{ sm: { m: 1 }, m: 1 }}
                label="Passport number"
                id="passport_no"
                error={!!errors.passport_no}
                helperText={errors?.passport_no?.message}
                variant="outlined"
                required
                fullWidth
              />
            )}
          />
        </div>

        <div className="sm:flex m-4">
          {choices && choices.ministry !== undefined && (
            <Controller
              name="ministry"
              control={control}
              defaultValue={[]}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  sx={{ m: 1 }}
                  // disablePortal
                  id="ministry"
                  fullWidth
                  options={ministries}
                  value={value}
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Ministry"
                      variant="outlined"
                    />
                  )}
                />
              )}
            />
          )}

          <Controller
            name="job_group"
            control={control}
            render={({ field }) => (
              <FormControl sx={{ mb: 2 }} fullWidth error={!!errors.job_group}>
                <InputLabel id="job_group1">Job group</InputLabel>
                <Select
                  labelId="job_group1"
                  id="job_group"
                  label="Job group"
                  variant="outlined"
                  {...field}
                >
                  <MenuItem onClick={() => setJob_group2("F4")} value={"F4"}>
                    F4
                  </MenuItem>
                  <MenuItem onClick={() => setJob_group2("U")} value={"U"}>
                    U
                  </MenuItem>
                  <MenuItem onClick={() => setJob_group2("V")} value={"V"}>
                    V
                  </MenuItem>
                  <MenuItem onClick={() => setJob_group2("E5")} value={"E5"}>
                    E5
                  </MenuItem>
                  <MenuItem onClick={() => setJob_group2("F1")} value={"F1"}>
                    F1
                  </MenuItem>
                  <MenuItem onClick={() => setJob_group2("F2")} value={"F2"}>
                    F2
                  </MenuItem>
                  <MenuItem onClick={() => setJob_group2("E3")} value={"E3"}>
                    E3
                  </MenuItem>
                  <MenuItem onClick={() => setJob_group2("E4")} value={"E4"}>
                    E4
                  </MenuItem>
                  <MenuItem onClick={() => setJob_group2("S")} value={"S"}>
                    S
                  </MenuItem>
                  <MenuItem onClick={() => setJob_group2("T")} value={"T"}>
                    T
                  </MenuItem>
                  <MenuItem onClick={() => setJob_group2("D5")} value={"D5"}>
                    D5
                  </MenuItem>
                  <MenuItem onClick={() => setJob_group2("E1")} value={"E1"}>
                    E1
                  </MenuItem>
                  <MenuItem onClick={() => setJob_group2("E2")} value={"E2"}>
                    E2
                  </MenuItem>
                  <MenuItem onClick={() => setJob_group2("P")} value={"P"}>
                    P
                  </MenuItem>
                  <MenuItem onClick={() => setJob_group2("Q")} value={"Q"}>
                    Q
                  </MenuItem>
                  <MenuItem onClick={() => setJob_group2("R")} value={"R"}>
                    R
                  </MenuItem>
                  <MenuItem onClick={() => setJob_group2("D1")} value={"D1"}>
                    D1
                  </MenuItem>
                  <MenuItem onClick={() => setJob_group2("D2")} value={"D2"}>
                    D2
                  </MenuItem>
                  <MenuItem onClick={() => setJob_group2("D3")} value={"D3"}>
                    D3
                  </MenuItem>
                  <MenuItem onClick={() => setJob_group2("K")} value={"K"}>
                    K
                  </MenuItem>
                  <MenuItem onClick={() => setJob_group2("L")} value={"L"}>
                    L
                  </MenuItem>
                  <MenuItem onClick={() => setJob_group2("M")} value={"M"}>
                    M
                  </MenuItem>
                  <MenuItem onClick={() => setJob_group2("N")} value={"N"}>
                    N
                  </MenuItem>
                  <MenuItem onClick={() => setJob_group2("F")} value={"F"}>
                    F
                  </MenuItem>
                  <MenuItem onClick={() => setJob_group2("G")} value={"G"}>
                    G
                  </MenuItem>
                  <MenuItem onClick={() => setJob_group2("H")} value={"H"}>
                    H
                  </MenuItem>
                  <MenuItem onClick={() => setJob_group2("I")} value={"I"}>
                    I
                  </MenuItem>
                  <MenuItem onClick={() => setJob_group2("C")} value={"J"}>
                    J
                  </MenuItem>
                  <MenuItem onClick={() => setJob_group2("A")} value={"A"}>
                    A
                  </MenuItem>
                  <MenuItem onClick={() => setJob_group2("B")} value={"B"}>
                    B
                  </MenuItem>
                  <MenuItem onClick={() => setJob_group2("C")} value={"C"}>
                    C
                  </MenuItem>
                  <MenuItem onClick={() => setJob_group2("D")} value={"D"}>
                    D
                  </MenuItem>
                  <MenuItem onClick={() => setJob_group2("E")} value={"E"}>
                    E
                  </MenuItem>
                </Select>
                <FormHelperText>{errors?.job_group?.message}</FormHelperText>
              </FormControl>
            )}
          />
        </div>

        <div className="sm:flex m-4">
          <DraftWysiwygEditor
            control={control}
            name="role"
            label="Role to be played during travel"
            errors={errors}
            defaultValue={delegate_data !== undefined ? delegate_data.role : ""}
          />

          {/* <Controller
            name="role"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                sx={{ sm: { m: 1 }, m: 1 }}
                label="Role - Explain in details:"
                type="text"
                id="role"
                error={!!errors.role}
                helperText={errors?.role?.message}
                variant="outlined"
                multiline
                minRows={3}
                required
                fullWidth
              />
            )}
          /> */}
        </div>

        {delegate_data !== undefined && (
          <div className="sm:flex m-4">
            {delegate_data.delegate_justification && (
              <>
                <Box
                  component={NavLinkAdapter}
                  to={`${backend_url}${delegate_data.delegate_justification}`}
                  target="_blank"
                  sx={{ backgroundColor: "background.paper" }}
                  className="flex flex-col relative w-full sm:w-160 h-auto m-8 p-16 shadow rounded-16 cursor-pointer no-underline"
                >
                  <div className="flex flex-auto w-full items-center justify-center">
                    <InfoIcon />
                  </div>
                  <div className="flex shrink flex-col justify-center text-center">
                    <Typography className="truncate text-12 font-medium">
                      {delegate_data.delegate_justification}
                    </Typography>
                  </div>
                </Box>

                <Controller
                  control={control}
                  name="delegate_justification1"
                  render={({ field: { onChange, value } }) => (
                    <Box
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.mode === "light"
                            ? lighten(theme.palette.background.default, 1)
                            : lighten(theme.palette.background.default, 0.02),
                      }}
                      component="label"
                      htmlFor="button_delegate_justification2"
                      className="flex items-center justify-center relative w-82 h-64 rounded-16 m-8 p-16  overflow-hidden cursor-pointer shadow hover:shadow-lg"
                    >
                      <input
                        accept=".jpeg, .jpg, .png, .pdf,"
                        className="hidden"
                        id="button_delegate_justification2"
                        type="file"
                        onChange={(e) => onChange(e.target.files[0])}
                      />
                      <IconButton>
                        <FileUploadIcon sx={{ color: "action" }} />
                      </IconButton>

                      <Typography
                        variant="p"
                        component="p"
                        className="text-xs font-small pl-8 pr-16"
                      >
                        {value === null ? (
                          <span> Delegate Justification Letter Upload</span>
                        ) : (
                          value.name
                        )}
                      </Typography>
                      <FormHelperText>
                        {" "}
                        <span className="flex flex-col text-red-500">
                          {errors?.delegate_justification1?.message}
                        </span>
                      </FormHelperText>
                    </Box>
                  )}
                />
              </>
            )}
          </div>
        )}

        {delegate_data_len !== undefined && (
          <div className="sm:flex m-4">
            {(trip_data.user.designation === "CS"
              ? delegate_data_len.length >= 3
              : trip_data.user.designation === "CEO"
              ? delegate_data_len.length >= 0
              : delegate_data_len.length >= 2) && (
              <>
                <Controller
                  control={control}
                  name="delegate_justification1"
                  render={({ field: { onChange, value } }) => (
                    <Box
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.mode === "light"
                            ? lighten(theme.palette.background.default, 1)
                            : lighten(theme.palette.background.default, 0.02),
                      }}
                      component="label"
                      htmlFor="button_delegate_justification1"
                      className="flex items-center justify-center relative w-82 h-64 rounded-16 m-8 p-16 overflow-hidden cursor-pointer shadow hover:shadow-lg"
                    >
                      <input
                        accept=".jpeg, .jpg, .png, .pdf,"
                        className="hidden"
                        id="button_delegate_justification1"
                        type="file"
                        onChange={(e) => onChange(e.target.files[0])}
                      />
                      <IconButton>
                        <FileUploadIcon sx={{ color: "action" }} />
                      </IconButton>

                      <Typography
                        variant="p"
                        component="p"
                        className="text-xs font-small pl-8 pr-16"
                      >
                        {value === null ? (
                          <span> Delegate Justification Letter Upload</span>
                        ) : (
                          value.name
                        )}
                      </Typography>
                      <FormHelperText>
                        {" "}
                        <span className="flex flex-col text-red-500">
                          {errors?.delegate_justification1?.message}
                        </span>
                      </FormHelperText>
                    </Box>
                  )}
                />
              </>
            )}
          </div>
        )}

        <div className="py-8 sm:py-16"></div>

        <TableContainer
          component={Paper}
          elevation={1}
          sx={{ paddingBottom: 0, marginBottom: 3 }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow sx={{ backgroundColor: "primary.light" }}>
                {/* <TableCell>U.S Dollar to Ksh as per today CBK rates</TableCell> */}
                <TableCell align="right">Number of days travelling</TableCell>
                <TableCell align="right">Country</TableCell>
                <TableCell align="right">Job group</TableCell>
                <TableCell align="right">
                  Subsistence/Accommodation (Per Diem) in &nbsp;(U.S $)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {/* <TableCell component="th" scope="row">
                  <TextField
                    disabled={
                      trip_data !== undefined &&
                      trip_data.sponsor_status === "not_sponsored"
                        ? true
                        : false
                    }
                    value={dollar_ksh}
                    onChange={(e) => {
                      setDollarKsh(e.target.value);
                    }}
                    label="U.S Dollar to Ksh rate"
                    id="dollar_ksh"
                    error={!!errors.dollar_ksh}
                    helperText={errors?.dollar_ksh?.message}
                    variant="outlined"
                    fullWidth
                  />
                </TableCell> */}
                <TableCell align="right">{days_rounded}</TableCell>
                <TableCell align="right">{country}</TableCell>
                <TableCell align="right">{job_group1}</TableCell>
                <TableCell align="right">
                  <b>
                    {" "}
                    {per_diem === ("__" || 0 || null || undefined)
                      ? accomodation_cost
                      : per_diem.toLocaleString()}
                  </b>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <div className="sm:flex m-4 py-16">
          <Controller
            name="ticket_cost"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={
                  trip_data !== undefined &&
                  trip_data.sponsor_status === "not_sponsored"
                    ? true
                    : false
                }
                label="b) Proposed ticket/ top up cost in Ksh"
                id="ticket_cost"
                error={!!errors.ticket_cost}
                helperText={errors?.ticket_cost?.message}
                variant="outlined"
                sx={{ sm: { m: 1 }, m: 1 }}
                fullWidth
              />
            )}
          />

          <Controller
            name="participant_cost"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                disabled={
                  trip_data !== undefined &&
                  trip_data.sponsor_status === "not_sponsored"
                    ? true
                    : false
                }
                label="f) Participation Fee (where applicable) in Ksh:"
                id="participant_cost"
                error={!!errors.participant_cost}
                helperText={errors?.participant_cost?.message}
                variant="outlined"
                sx={{ sm: { m: 1 }, m: 1 }}
                fullWidth
              />
            )}
          />
        </div>

        {window.location.hash === "#preview" ? (
          <div></div>
        ) : (
          <Button
            sx={{ mt: 6 }}
            variant="contained"
            color="success"
            disabled={loading === true ? true : false}
            onClick={() => {
              setOpenMessage(false);
              setLoading(Object.keys(errors).length > 0 ? false : true);
            }}
            type="submit"
          >
            {loading === true ? (
              <Loading />
            ) : Object.keys(errors).length > 0 ? (
              "Complete All Fields"
            ) : Object.keys(errors).length > 0 ? (
              setLoading(false)
            ) : (
              "Update delegate details"
            )}
          </Button>
        )}
      </div>
    </form>
  );
}
