import { useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { styled } from "@mui/material/styles";
import format from "date-fns/format";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TravelSnackbar from "../../components/TravelSnackbar";
import Loading from "../../components/Loading";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
// import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import { backend_url } from "../../components/Constants";
import { useNavigate } from "react-router-dom";
// import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
// import EditIcon from "@mui/icons-material/Edit";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import DraftWysiwygEditor from "../../forms/editor/TextEditor";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function CheckingSummary(props) {
  const {
    token,
    id,
    setChecking_confirm,
    checking_confirm,
    trip,
    depRet,
    delegate_data,
  } = props;
  const [openMessage, setOpenMessage] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [dollar_ksh, setDollarKsh] = useState(
    trip.sponsor_status === "not_sponsored" ? 0 : null
  );

  const schema = yup.object().shape({
    dollar_ksh: yup.string().required("This field is required"),
    hops_checking_reasons:
      checking_confirm !== "accepted"
        ? yup.string().required("This field is required")
        : yup.string().notRequired(),
    hops_checking_recommendation: yup.string().notRequired(),
  });

  const defaultValues = {
    hops_checking_reasons: "",
    hops_checking_recommendation: "",
  };

  const delLength = delegate_data.length;
  const days =
    (new Date(depRet.date_of_return).getTime() -
      new Date(depRet.date_of_dep).getTime()) /
    (1000 * 3600 * 24);
  const days_rounded =
    days.toString().charAt(1) === "."
      ? parseInt(days.toString().charAt(0)) + 1
      : days;

  const tot_cost = [
    parseInt(trip.accomo_cost) +
      parseInt(trip.ticket_cost) +
      parseInt(trip.participant_cost),
  ];
  const tot_cost_1 = [
    // parseInt(trip.accomo_cost) +
    parseInt(trip.ticket_cost) + parseInt(trip.participant_cost),
  ];

  const all_del_cost = parseInt(
    delegate_data.map((delegate) =>
      tot_cost.push(
        parseInt(delegate.accomo_cost) +
          parseInt(delegate.ticket_cost) +
          parseInt(delegate.participant_cost)
      )
    )
  );

  const accomodation_cost = trip.accomo_cost;
  const per_diem = dollar_ksh !== null ? accomodation_cost * dollar_ksh : "__";

  const sumsKES = tot_cost.reduce((a, x, i) => [...a, x + (i && a[i - 1])], []);
  const sumsDollar = tot_cost_1.reduce(
    (a, x, i) => [...a, x + (i && a[i - 1])],
    []
  );

  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    // isValid, dirtyFields,
    errors,
  } = formState;

  const navigate = useNavigate();
  function submit({ hops_checking_reasons, hops_checking_recommendation }) {
    const checkingInfo = {
      trip_id: id,
      hops_checking_confirm: checking_confirm,
      hops_checking_reasons,
      hops_checking_recommendation,
      accomo_cost: per_diem,
    };

    let url = `${backend_url}/traveller/confirmation/`;
    axios
      .patch(url, checkingInfo, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setOpenMessage(true);
        setSeverity("success");
        setMessage("Confirmed Successfully!");
        setLoading(false);
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
        setOpenMessage(true);
        setSeverity("error");
        setMessage(
          err.response.data?.details
            ? err.response.data?.details
            : err.response.data?.error
        );
        setLoading(false);
      });
  }

  return (
    <div className="py-4 w-full max-w-3xl">
      <Typography
        component="h5"
        variant="p"
        className="px-4 py-16 text-lg font-bold"
      >
        TRAVEL CLEARANCE REQUEST SUMMARY FOR TRIP {id}
      </Typography>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableBody>
            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                Application Ref: No
              </StyledTableCell>
              <StyledTableCell align="left">{id}</StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                Date of application:
              </StyledTableCell>
              <StyledTableCell align="left">
                {format(new Date(trip.created_at), "dd-MMM-yyyy")}
              </StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                Date Received:
              </StyledTableCell>
              <StyledTableCell align="left">
                {format(new Date(trip.updated_at), "dd-MMM-yyyy")}
              </StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                Applicant:
              </StyledTableCell>
              <StyledTableCell align="left">
                {`${
                  trip.user.salutation !== undefined && trip.user.salutation
                }. ${trip.user.first_name}  ${trip.user.last_name}`}
              </StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                Designation:
              </StyledTableCell>
              <StyledTableCell align="left">
                {trip.user.designation}
              </StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                MDA:
              </StyledTableCell>
              <StyledTableCell align="left">
                {trip.user.ministry}
              </StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                Days Requested:
              </StyledTableCell>
              <StyledTableCell align="left">{`${days_rounded} days`}</StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                Dates:
              </StyledTableCell>
              <StyledTableCell align="left">{`${format(
                new Date(depRet.date_of_dep),
                "dd-MMM-yyyy"
              )} -  ${format(
                new Date(depRet.date_of_return),
                "dd-MMM-yyyy"
              )}`}</StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                No. of Days Remaining on Applicants Docket:
              </StyledTableCell>
              <StyledTableCell align="left">
                {15 - trip.days_this_quarter}
              </StyledTableCell>
            </StyledTableRow>

            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                Purpose for travel:
              </StyledTableCell>
              <StyledTableCell align="left">
                {/* {trip.reason_for_tr} */}
                <div
                      dangerouslySetInnerHTML={{
                        __html: trip.reason_for_tr,
                      }}
                    />
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                Destination:
              </StyledTableCell>
              <StyledTableCell align="left">{trip.country}</StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                Delegation Nos:
              </StyledTableCell>
              <StyledTableCell align="left">{delLength}</StyledTableCell>
            </StyledTableRow>
            {trip.hops_checking_confirm === "not_confirmed" ? (
              <StyledTableRow>
                <StyledTableCell align="left" component="th" scope="row">
                  Total Cost:
                </StyledTableCell>
                <StyledTableCell align="left">
                  Participation Fee & Ticket Cost(KES):{" "}
                  {sumsDollar[sumsDollar.length - 1].toLocaleString()} -
                  Accomodation Cost($): {trip.accomo_cost}
                </StyledTableCell>
              </StyledTableRow>
            ) : (
              <StyledTableRow>
                <StyledTableCell align="left" component="th" scope="row">
                  Total Cost(KES):
                </StyledTableCell>
                <StyledTableCell align="left">
                  {sumsKES[sumsKES.length - 1].toLocaleString()}
                </StyledTableCell>
              </StyledTableRow>
            )}
            <StyledTableRow>
              <StyledTableCell align="left" component="th" scope="row">
                Sponsor:
              </StyledTableCell>
              <StyledTableCell align="left">{trip.sponsor}</StyledTableCell>
            </StyledTableRow>
            {trip.chairperson_reason !== null &&
              (trip.chairperson_confirm === "accepted" ||
                trip.chairperson_confirm === "accepted") && (
                <StyledTableRow>
                  <StyledTableCell align="left" component="th" scope="row">
                    Chairperson Comments:
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {trip.chairperson_reason}
                  </StyledTableCell>
                </StyledTableRow>
              )}
            {trip.ps_reasons !== null &&
              (trip.ps_confirm === "accepted" ||
                trip.ps_confirm === "rejected") && (
                <StyledTableRow>
                  <StyledTableCell align="left" component="th" scope="row">
                    PS Comments:
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {trip.ps_reasons}
                  </StyledTableCell>
                </StyledTableRow>
              )}
            {trip.cs_reasons !== null &&
              (trip.cs_confirm === "accepted" ||
                trip.cs_confirm === "rejected") && (
                <StyledTableRow>
                  <StyledTableCell align="left" component="th" scope="row">
                    CS Comments:
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {trip.cs_reasons}
                  </StyledTableCell>
                </StyledTableRow>
              )}
          </TableBody>
        </Table>
      </TableContainer>

      {trip.hops_checking_confirm === "not_confirmed" && (
        <form
          name="checkingForm"
          noValidate
          className="flex flex-col justify-center w-full mt-4 sm:pt-4 sm:mt-8 mb-16"
          onSubmit={handleSubmit(submit)}
        >
          {openMessage === true && (
            <TravelSnackbar
              openMessage={openMessage}
              severity={severity}
              message={message}
              uploads={true}
            />
          )}

          <Controller
            name="dollar_ksh"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="U.S Dollar to Ksh rate:"
                id="dollar_ksh"
                variant="outlined"
                sx={{ p: 1, marginBottom: '12px' }}
                error={!!errors.dollar_ksh}
                helperText={errors?.dollar_ksh?.message}
                fullWidth
                onChange={(e) => {
                  setDollarKsh(e.target.value);
                  field.onChange(e.target.value); // Ensure form value is updated
                }}
              />
            )}
          />

          {/* <Controller
            name="hops_checking_reasons"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Comments:"
                id="hops_checking_reasons"
                variant="outlined"
                sx={{ p: 1 }}
                error={!!errors.hops_checking_reasons}
                helperText={errors?.hops_checking_reasons?.message}
                fullWidth
                multiline
                minRows={3}
              />
            )}
          /> */}

          <DraftWysiwygEditor
            control={control}
            name="hops_checking_reasons"
            label="Comments:"
            errors={errors}
          />

          {/* <Controller
          name="hops_checking_recommendation"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              label="Recommendations:"
              id="hops_checking_recommendation"
              variant="outlined"
              sx={{ p: 1 }}
              error={!!errors.hops_checking_recommendation}
              helperText={errors?.hops_checking_recommendation?.message}
              fullWidth
              multiline
              minRows={3}
            />
          )}
        /> */}

          <div className="flex items-center mt-16 sm:mx-8 space-x-6">
            <Button
              variant="contained"
              color="error"
              // disabled={_.isEmpty(dirtyFields) || !isValid}
              onClick={() => {
                setChecking_confirm("rejected");
                setOpenMessage(false);
              }}
              startIcon={<DoDisturbIcon />}
              type="submit"
            >
              {loading === true ? <Loading /> : "REJECT CONFIRMATION"}
            </Button>
            {/* <Button
            className="whitespace-nowrap"
            variant="contained"
            color="primary"
            onClick={() => {
              setChecking_confirm("return_application");
              setOpenMessage(false);
            }}
            startIcon={<AssignmentReturnIcon />}
            type="submit"
          >
            {loading === true ? <Loading /> : "Return Application"}
          </Button> */}

            <Button
              variant="contained"
              color="success"
              // disabled={_.isEmpty(dirtyFields) || !isValid}
              onClick={() => {
                setChecking_confirm("accepted");
                setOpenMessage(false);
              }}
              startIcon={<CheckCircleOutlineIcon />}
              type="submit"
            >
              {loading === true ? <Loading /> : "MARK AS CONFIRMED"}
            </Button>

            {/* <Button
            className="whitespace-nowrap"
            variant="contained"
            color="primary"
            component={Link}
            to={`/checking/edit/${trip.trip_id}`}
            startIcon={<EditIcon />}
          >
            Edit Application
          </Button> */}
          </div>
        </form>
      )}
    </div>
  );
}
